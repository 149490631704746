// cn.js
const CN = {
    type: "CN",
    lang: "中文",

    // 首页-页眉字符
    index_1: "首页",
    index_2: "关于我们",
    index_3: "公司简介",
    index_4: "荣誉资质",
    index_5: "企业文化",
    index_6: "产品中心",
    index_7: "新闻资讯",
    index_8: "服务支持",
    index_9: "项目合作",
    index_10: "售后服务",
    index_11: "资料下载",
    index_12: "服务热线",
    index_13: "",

    // 首页-主体字符
    index_21: "最新动态",
    index_22: "最新产品",
    index_23: "联系我们",
    index_24: "业务咨询：153-6288-8085",
    index_24_1: "电话：153-6288-8085",
    index_25: "联系邮箱：zhangjiayu@gdzhongyan.co",
    index_25_1: "邮箱：zhangjiayu@gdzhongyan.co",
    index_26: "所在地区：广东省东莞市常平镇",
    index_27: "详细地址：",
    index_27_1: "地址：",
    index_28: "环常北路珠宝文化产业园32栋14楼",
    index_28_1: "环常北路珠宝文化产业园32栋14楼",
    index_29: "打开导航",
    index_30: "请选择导航软件",
    index_31: "高德地图",
    index_32: "腾讯地图",
    index_33: "百度地图",
    index_34: "广东众研科技有限公司成立于2019年，是一家专业研发、生产、销售射频类读卡器门禁控制器二维码读卡器人脸识别应用系统的方案提供商。公司自成立以来，始终坚持以人才为本，诚信立业的经营原则，荟萃业果精英，以客户的需求为己任，提供品质优良价格低廉的成熟方案。",
    index_35: "公司的产品目前在门禁安全，通道管理智慧校园工业控制系统等领域具有广泛的应用。众研科技愿以永不停歇的脚步，执着进取的精神，为广大客户提供硬为完美高效可依赖的产品及解决方案，成为您发展，共赢的理想合作伙伴。",
    index_35_1: "我们热忱欢迎各界合作伙伴的加入，共同开创科技发展的新篇章。如果您对我们的产品或服务有任何疑问或合作意向，欢迎随时与我们联系。",
    index_36: "技术实力",
    index_37: "公司先后推出的门禁读卡器、二维码读卡器、动态二维码门禁、人脸识别、射频模块、门禁机等类型产品。",
    index_38: "成功案例",
    index_39: "凭借超前的技术优势和过硬的品质，得到了用户的一致好评！此外，公司拥有高效的研发团队和一流的工业控制系统，可以根据用户需求实现快速定制，并提门禁安全，通道管理的整体解决方案。",
    index_40: "查看众研的最新动态",
    index_41: "查看",
    index_42: "了解更多",
    index_43: "售后服务：136-2007-5120",
    index_44: "技术支持：181-1875-4449",
    index_45: "广东众研科技有限公司",
    index_46: "深圳市众研科技有限公司",

    // 首页-页脚字符
    foot_1: "为什么选择众研",
    foot_2: "最新产品",
    foot_3: "获取最新资讯",
    foot_4: "感谢您的关注",
    foot_5: "是的，希望众研与我联系，以获取新闻通讯、促销和活动。",
    foot_6: "提交",
    foot_7: "官方微信",
    foot_8: "手机端",
    foot_9: "邮箱地址不能为空",
    foot_10: "邮箱地址不符合规范",
    foot_11: "请勾选用户协议",

    // 手机底部导航栏
    mobile_foot1: "首页",
    mobile_foot2: "产品",
    mobile_foot3: "资讯",
    mobile_foot4: "文档",

    // 其他页面
    text1: "请输入内容",
    text2: "正在加载...",
    text3: "立即咨询",
    text4: "公司名称",
    text5: "产品名称",
    text6: "联系方式",
    text7: "备注",
    text8: "支持手机、邮箱、固定电话等联系方式，我们会在第一时间联系您。",
    text9: "请选择语言",
    text10: "产品详情",
    text11: "产品文档",

}
export default CN
