<template>
    <div class="main home" style="min-height: 800px">
        <!-- 电脑端页面  -->
        <el-row :gutter="20" v-if="!this.GLOBAL.isMobile">
            <el-col :span="21" :offset="3">
                <div style="min-height: 800px">
                    <el-row>
                        <el-col :span="16" :offset="4">
                            <div style="margin: 35px">
                                <el-input :placeholder="$t('text1')" v-model="searchValue" class="input-with-select">
                                    <el-button
                                        slot="append"
                                        icon="el-icon-search"
                                        @click="initPageList()"
                                    ></el-button>
                                </el-input>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="4">
                            <div class="lside">
                                <h2>{{$t('index_6')}}</h2>
                                <ul class="iauth">
                                    <li v-for="productType in productTypeList"
                                        @click="$router.push('/productList?type=' + productType.id)">
                                        <a :href="'#/productList?type=' + productType.id"
                                           :title="productType.name"
                                           :class="[(goodsType === productType.id?'act':'') + ' tarA']">
                                            {{ productType.name }}
                                        </a>
                                    </li>
                                </ul>

                                <div class="bdiv">
                                    <h3>{{$t('index_23')}}</h3>
                                    <div class="contact_l"> {{$t('index_24_1')}}<br>
                                        {{$t('index_25_1')}}<br>
                                        {{$t('index_27_1')}}<a target="_blank" style="color: #0a0a0a" href="https://www.amap.com/search?query=广东省东莞市常平镇环常北路珠宝文化产业园">{{$t('index_28_1')}}</a><br>
                                        <a href="#/item" class="zxun"> {{$t('text3')}} </a>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="18">
                            <div style="min-height: 700px">
                                <div class="columns is-multiline features visible">
                                    <article v-for="product in productPageList"
                                             class="feature column is-half-tablet is-one-third-desktop is-one-quarter-widescreen">
                                        <a class="box is-special has-text-centered"
                                           :href="['#/productDetail?id=' + product.id]"
                                           name="&amp;lpos=apps_scodevmw : 36"
                                           onclick="s_objectID='apps_scodevmw : img/icons/reactive.svg : 36'">
                                            <img :src="[cp + '/jyy/billFile/getFile?uuid=' + product.showPicUuid]"
                                                 style="max-width: 200px;max-height: 200px;width: 100%;height: 100%;"
                                            />
                                            <h1 class="is-size-4 has-text-weight-bold has-text-centered mb-4">
                                                {{ product.productName }} {{ product.productSpec }}
                                            </h1>
                                            <p class="has-text-centered">{{ product.showTitle }}</p>
                                        </a>
                                    </article>
                                </div>
                            </div>
                            <div style="height: 50px; text-align: center">
                                <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :page-size="pageSize"
                                    :pager-count="11"
                                    layout="prev, pager, next"
                                    :total="total"
                                ></el-pagination>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
            <el-col :span="4"></el-col>
        </el-row>


        <!-- 手机版页面  -->
        <div id="productListDiv" v-if="this.GLOBAL.isMobile" v-on:touchmove="isShow">
            <div style="min-height: 800px;background-color: #f5f5f5;">
                <div style="background-color: #ffffff; padding: 80px 10px 10px;">
                    <el-input :placeholder="$t('text1')" v-model="searchValue" class="input-with-select">
                        <el-button slot="append"
                                   icon="el-icon-search"
                                   @click="initPageList()"
                        ></el-button>
                    </el-input>
                </div>
                <div style="background-color: #ffffff; padding-left: 5px;padding-right: 5px;padding-bottom: 5px">
                    <div v-for="productType in productTypeList" class="menu-icon"
                         :class="productType.id===goodsType?'active' :''"
                         @click="$router.push('/productList?type=' + productType.id)">
                        {{ productType.name }}
                    </div>
                </div>
                <div>
                    <div style="display: flex;flex-flow:row wrap;padding-top: 10px;padding-left: 1%;padding-right: 1%;">
                        <div class="product-item" v-for="product in productPageList">
                            <a @click="$router.push({ path: '/productDetail', query: { id: product.id} })"
                               style="text-decoration:none">
                                <div style="width: 100%;">
                                    <img class="img-icon" :src="[cp + '/jyy/billFile/getFile?uuid=' + product.showPicUuid]"/>
                                </div>
                                <div class="product-name">
                                    {{ product.productName }} {{ product.productSpec }}
                                </div>
                                <div class="product-showTitle">{{ product.showTitle }}</div>
                            </a>
                        </div>
                    </div>
                    <div id="getMoreDiv" style="padding-bottom: 100px">
                        <div v-if="total > page * pageSize" style="text-align: center" @click="getMore()">
                            {{ $t('text2') }}
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </div>
</template>

<script>
import {getPageParamStr, getParam} from '@/utils/utils'
import axios from '@/utils/axios'
import PicZoom from "@/components/PicZoom.vue";


export default {
    components: {PicZoom},
    data() {
        return {
            cp: this.GLOBAL.cp,
            page: 1,
            pageSize: 8,
            total: 0,
            searchValue: '',
            goodsType: '',
            productTypeList: [],
            productPageList: [],
            loading: false,
        }
    },
    watch: {
        $route(to, from) {
            this.goodsType = getParam('type')
            this.productPageList = []
            this.initPageList()
        },

    },
    mounted() {
        this.goodsType = getParam('type')
        axios.get('/jyy/portal/getProductTypeList')
            .then(res => {
                this.productTypeList = res.data
                this.initPageList()
            })
    },
    methods: {
        initPageList() {
            this.loading = true;
            let searchValue = encodeURIComponent(this.searchValue)
            let url = '/jyy/portal/selectProductPage?searchValue=' + searchValue +
                '&goodsType=' + this.goodsType +
                getPageParamStr(this.page, this.pageSize)
            axios.get(url).then(res => {
                this.page = res.data.page
                this.pageSize = res.data.pageSize
                this.total = res.data.total
                if (this.GLOBAL.isMobile) {
                    this.productPageList = this.productPageList.concat(res.data.rows)
                } else {
                    this.productPageList = res.data.rows
                }
                this.loading = false;
            })
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.initPageList()
        },
        handleCurrentChange(val) {
            this.page = val
            this.initPageList()
        },
        isShow() {
            const element = document.getElementById("getMoreDiv");
            const innerHeight = window.innerHeight;
            const clientHeight = element.clientHeight;
            // console.log(innerHeight)
            // console.log(clientHeight)
            const {top, right, bottom, left} = element.getBoundingClientRect();
            // console.log(innerHeight, clientHeight, top, right, bottom, left)
            if (top < innerHeight - clientHeight + 100 && !this.loading && this.total > this.page * this.pageSize) {
                this.page = this.page + 1
                this.initPageList()
            }
        },
    },
}
</script>


<style scoped>


.lside {
    float: left;
    position: relative;
    width: 100%;
    margin-right: 2rem;
    z-index: 300
}

.lside h2 {
    font-size: 1.2rem;
    color: #fff;
    background-color: #e82f26;
    text-align: left;
    box-sizing: border-box;
    font-weight: 700;
    line-height: 3.4;
    text-indent: 1em;
    background-repeat: no-repeat;
    background-position: 80% center;
    background-size: auto auto
}

.lside .iauth {
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-top: none
}

.lside .iauth li {
    position: relative;
    border-bottom: 1px solid #ddd
}

.lside .iauth li:last-child {
    border: none
}

.lside .iauth a {
    text-align: left;
    font-size: 1.09rem;
    color: #333;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 3.2;
    text-indent: 1.2em;
    padding-right: 1em
}

.lside .iauth a::after {
    display: block;
    font-weight: 700;
    content: ">";
    z-index: 5;
    position: absolute;
    right: 10px;
    top: 0
}

.lside .iauth a.act, .lside .iauth a:hover {
    color: #e82f26;
    background-color: #f6f6f6;
    border-left: 4px solid #e82f26
}

.lside .bdiv {
    border: 1px solid #ddd;
    margin-top: 2rem;
    padding: 0 .8rem .8rem
}

.lside h3 {
    font-size: 1.2rem;
    color: #e82f26;
    font-weight: 700;
    line-height: 2.6;
    border-bottom: 1px solid #ddd
}


.lside .contact_l {
    padding-top: 1em;
    line-height: 2;
    padding-left: 5px
}


.listit {
    border-bottom: 1px solid #ddd;
    overflow: visible;
    margin-bottom: 2rem;
    text-align: center
}

.listit h2 {
    font-size: 1.2rem;
    font-weight: 700;
    color: #e82f26;
    display: inline-block;
    position: relative;
    background-color: #fff;
    margin-bottom: -2px;
    line-height: 3.4
}

.listit h2::after {
    display: block;
    background-color: #e82f26;
    content: " ";
    height: 3px;
    width: 60%;
    z-index: 5;
    position: absolute;
    left: 20%;
    bottom: 0
}

.menu-icon {
    display: inline-block;
    background-color: #f5f7fa;
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 35px;
    border: 1px solid #f5f7fa;
}

.menu-icon.active {
    display: inline-block;
    background-color: #e82f26;
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 35px;
    border: 1px solid #e82f26;
    color: #ffffff;
}

.product-item {
    width: 48%;
    margin-right: 1%;
    margin-left: 1%;
    margin-bottom: 10px;
    border-radius: 7px;
    background-color: #ffffff;
}

.img-icon {
    border-radius: 7px 7px 0 0;
    width: 100%;
    height: 100%;
}

.product-name {
    font-weight: bold;
    color: #000000;
    font-size: 15px;
    padding: 0 5px 0 5px;
}

.product-showTitle {
    color: #000000;
    font-size: 14px;
    padding: 0 5px 0 5px;
}


</style>


