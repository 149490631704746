<template>
    <footer class="footer has-background-white">
        <div class="content has-text-left">
            <div class="container mb-6 links">
                <div class="columns is-desktop">
                    <div class="column is-7-desktop is-10-tablet">
                        <div class="columns is-multiline is-mobile">
                            <div class="column">
                                <div class="item has-text-weight-bold">
                                    <a href="javascript:;">{{ $t('foot_1') }}</a>
                                </div>

                                <div class="item">
                                    <a @click="$router.push({ path: '/companyInfo', query: { type: 'GSJJ' } })">
                                        {{ $t('index_3') }}
                                    </a>
                                </div>
                                <div class="item">
                                    <a @click="$router.push({ path: '/companyInfo', query: { type: 'QYWH' } })">
                                        {{ $t('index_4') }}
                                    </a>
                                </div>
                                <div class="item">
                                    <a @click="$router.push({ path: '/companyInfo', query: { type: 'RYZZ' } })">
                                        {{ $t('index_5') }}
                                    </a>
                                </div>
                            </div>
                            <div class="column">
                                <div class="item has-text-weight-bold"><a href="javascript:;">{{ $t('foot_2') }}</a></div>
                                <div class="item" v-for="productType in productTypeList">
                                    <a :href="'#/productList?type=' + productType.id">{{ productType.name }}</a>
                                </div>

                            </div>
                            <div class="column">
                                <div class="item has-text-weight-bold">
                                    <a href="javascript:;">{{ $t('index_8') }}</a>
                                </div>
                                <div class="item"><a @click="$router.push('/item')">{{ $t('index_9') }}</a></div>
                                <div class="item"><a @click="$router.push('/sh')">{{ $t('index_10') }}</a></div>
                                <div class="item"><a @click="$router.push('/fileDownloadList')">{{ $t('index_11') }}</a></div>
                            </div>
                            <!--  <div class="column">
                                <div class="item has-text-weight-bold"><a href="javascript:">最新项目</a></div>
                                <div class="item has-text-weight-bold"><a href="javascript:">Training</a></div>
                                <div class="item has-text-weight-bold mt-4">
                                    <a href="javascript:;">谢谢</a>
                                </div>
                            </div>-->
                        </div>
                    </div>
                    <div class="column is-5-desktop is-10-mobile is-10-tablet">
                        <div class="newsletter">
                            <h2 class="is-size-4 has-text-weight-bold">{{ $t('foot_3') }}</h2>
                            <div class="mt-4">
                                <div id="newsletter-footer">
                                    <h4 class="mb-2 hide">{{ $t('foot_4') }}</h4>
                                    <form id="mktoForm_4723" novalidate="novalidate"
                                          class="mktoForm mktoHasWidth mktoLayoutLeft"
                                          style="font-family: Helvetica, Arial, sans-serif;font-size: 13px;color: rgb(51, 51, 51);width: 301px;">
                                        <div class="mktoFormRow">
                                            <div class="mktoFieldDescriptor mktoFormCol" style="margin-bottom: 10px">
                                                <div class="mktoOffset" style="width: 10px"></div>
                                                <div class="mktoFieldWrap mktoRequiredField">
                                                    <label
                                                        for="Email"
                                                        id="LblEmail"
                                                        class="mktoLabel mktoHasWidth"
                                                        style="width: 0"
                                                    >
                                                        <div class="mktoAsterix">*</div>
                                                    </label>
                                                    <div class="mktoGutter mktoHasWidth" style="width: 10px"></div>
                                                    <input id="Email" name="Email" v-model="email"
                                                           placeholder="Email Address"
                                                           maxlength="255"
                                                           aria-labelledby="LblEmail InstructEmail"
                                                           type="email"
                                                           class="mktoField mktoEmailField mktoHasWidth mktoRequired"
                                                           aria-required="true"
                                                           style="width: 150px"
                                                    />
                                                    <span id="InstructEmail" tabindex="-1"
                                                          class="mktoInstruction"></span>
                                                    <div class="mktoClear"></div>
                                                </div>
                                                <div class="mktoClear"></div>
                                            </div>
                                            <div class="mktoClear"></div>
                                        </div>
                                        <div class="mktoFormRow">
                                            <div class="mktoFieldDescriptor mktoFormCol" style="margin-bottom: 10px">
                                                <div class="mktoOffset" style="width: 10px"></div>
                                                <div class="mktoFieldWrap mktoRequiredField">
                                                    <label id="LblEmail_Consent__c" class="mktoLabel mktoHasWidth"
                                                           style="width: 0px">
                                                        <div class="mktoAsterix">*</div>
                                                    </label>
                                                    <div class="mktoGutter mktoHasWidth" style="width: 10px"></div>
                                                    <div
                                                        class="mktoLogicalField mktoCheckboxList mktoHasWidth mktoRequired"
                                                        style="width: 280px">
                                                        <input type="checkbox" v-model="agree" class="mktoField"/>
                                                        <label for="mktoCheckbox_121736_0"
                                                               id="LblmktoCheckbox_121736_0">
                                                            {{ $t('foot_5') }}
                                                        </label>
                                                    </div>
                                                    <span id="InstructEmail_Consent__c" tabindex="-1"
                                                          class="mktoInstruction"></span>
                                                    <div class="mktoClear"></div>
                                                </div>
                                                <div class="mktoClear"></div>
                                            </div>
                                            <div class="mktoClear"></div>
                                        </div>
                                        <div class="mktoButtonRow">
                                            <span class="mktoButtonWrap mktoSimple" style="margin-left: 120px">
                                              <button @click="subscribe" type="button" class="mktoButton">{{ $t('foot_6') }}</button>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container more">
                <div class="columns">
                    <div class="column">
                        <p>
                            <img src="../assets/icon_url.png" width="200px"/>
                        </p>
                        <p class="has-text-grey">
<!--                            业务质询：153-6288-8085 售后服务：136-2007-5120 &nbsp;&nbsp; 技术支持：181-1875-4449-->
                        </p>
                    </div>
                    <div style="float: right; text-align: center;margin-right: 20px">
                        <img src="../assets/weixin.png" width="120px"/>
                        <div>{{ $t('foot_7') }}</div>
                    </div>
                    <div style="float: right; text-align: center;margin-right: 20px;color: #d10a29">
                        <img src="../assets/QR_URL.png" width="120px"/>
                        <div>{{ $t('foot_8') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import axios from "@/utils/axios";
import {getParam} from "@/utils/utils";

export default {
    name: 'IndexFooter',
    data() {
        return {
            productTypeList: null,
            newTypeList: null,
            email: '',
            agree: false,
        }
    },
    mounted() {
        axios.get('/jyy/portal/getProductTypeList')
            .then(res => {
                this.productTypeList = res.data
            })

    },
    methods: {
        subscribe() {
            if (this.email == null || this.email == '') {
                this.$message({
                    showClose: true,
                    message: this.$t('foot_9'),
                    type: 'error',
                    duration: 5000,
                })
                return false;
            }
            const pattern = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/;
            if (!pattern.test(this.email)) {
                this.$message({
                    showClose: true,
                    message: this.$t('foot_10'),
                    type: 'error',
                    duration: 5000,
                })
                return false;
            }
            if (!this.agree) {
                this.$message({
                    showClose: true,
                    message: this.$t('foot_11'),
                    type: 'error',
                    duration: 5000,
                })
                return false;
            }
            axios.get('/jyy/portal/addEmail?email=' + this.email)
                .then(res => {
                    if (res.data.code === 0) {
                        this.$message({
                            showClose: true,
                            message: res.data.msg,
                            type: 'success',
                        })
                    } else {
                        this.$message({
                            showClose: true,
                            message: res.data.msg,
                            type: 'error',
                        })
                    }
                })
        },
    }
}
</script>

<style lang="less" scoped>
// #99c47c #75ae4c

.mktoForm .mktoButtonWrap.mktoSimple .mktoButton {
    color: #fff;
    border: 1px solid #e82f26;
    padding: 0.4em 1em;
    font-size: 1em;
    background-color: #d10a29;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#d10a29), to(#e82f26));
    background-image: -webkit-linear-gradient(top, #d10a29, #e82f26);
    background-image: -moz-linear-gradient(top, #d10a29, #e82f26);
    background-image: linear-gradient(to bottom, #d10a29, #e82f26);
}

.mktoForm .mktoButtonWrap.mktoSimple .mktoButton:hover {
    border: 1px solid #e82f26;
}

.mktoForm .mktoButtonWrap.mktoSimple .mktoButton:focus {
    outline: none;
    border: 1px solid #e82f26;
}

.mktoForm .mktoButtonWrap.mktoSimple .mktoButton:active {
    background-color: #e82f26;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e82f26), to(#d10a29));
    background-image: -webkit-linear-gradient(top, #e82f26, #d10a29);
    background-image: -moz-linear-gradient(top, #e82f26, #d10a29);
    background-image: linear-gradient(to bottom, #e82f26, #d10a29);
}
</style>
