// cn.js
const EN={
    type: "EN",
    lang:"English",


    // 首页字符
    index_1: "Home",
    index_2: "About Us",
    index_3: "Company Profile",
    index_4: "Honorary Qualifications",
    index_5: "Corporate Culture",
    index_6: "Products",
    index_7: "News",
    index_8: "Support",
    index_9: "Project Cooperation",
    index_10: "After Sale",
    index_11: "Download",
    index_12: "TEL",
    index_13: "",

    // 首页-主体字符
    index_21: "NEWS",
    index_22: "Products",
    index_23: "Contact Us",
    index_24: "Phone：+86 153-6288-8085",
    index_24_1: "Phone：+86 153-6288-8085",
    index_25: "Email：sandychanogdzhongyan.co",
    index_25_1: "Email：sandychanogdzhongyan.co",
    index_26: "Address：Shahe Town, Changping District, Beijing",
    index_27: "",
    index_27_1: "",
    index_28: "Beijing Xinfeida Electronic Technology Industrial Development Center F4-4, Baisha Road",
    index_28_1: "Beijing Xinfeida Electronic Technology Industrial Development Center F4-4, Baisha Road",
    index_29: "Open Navigation",
    index_30: "Please select navigation software",
    index_31: "AMAP",
    index_32: "Tencent Maps",
    index_33: "Baidu map",
    index_34: "Guangdong Zhongyan Technology Co., Ltd. was established in 2019 and is a professional solution provider for the research and development, production, and sales of RF card readers, access control controllers, QR code card readers, and facial recognition application systems. Since its establishment, the company has always adhered to the business principle of putting talent first and establishing a business with integrity. It has gathered outstanding achievements and talents, taking customer needs as its responsibility, and providing mature solutions with excellent quality and low prices.",
    index_35: "The company's products are currently widely used in areas such as access control security, channel management, and smart campus industrial control systems. Zhongyan Technology is willing to provide customers with hard, perfect, efficient and reliable products and solutions with a never-ending pace and a persistent spirit of progress, becoming your ideal partner for development and win-win situation.",
    index_35_1: "We warmly welcome partners from all walks of life to join us and jointly create a new chapter in technological development. If you have any questions or cooperation intentions about our products or services, please feel free to contact us at any time.",
    index_36: "Technology",
    index_37: "The company has successively launched various types of products, including access control card readers, QR code readers, dynamic QR code access control, facial recognition, RF modules, and access control machines.",
    index_38: "Success cases",
    index_39: "With advanced technological advantages and excellent quality, we have received unanimous praise from users! In addition, the company has an efficient research and development team and first-class industrial control systems, which can achieve rapid customization according to user needs, and provide an overall solution for access control security and channel management.",
    index_40: "Check out the latest updates on ZhongYan",
    index_41: "view",
    index_42: "Learn more",
    index_43: "After Sale：+86 136-2007-5120",
    index_44: "Technical Support：+86 181-1875-4449",
    index_45: "Guangdong & Shengzhen",
    index_46: "ZHONGYAN TECH",

    // 首页-页脚字符
    foot_1: "Why choose ZhongYan",
    foot_2: "PRODUCT",
    foot_3: "Get The Latest Information",
    foot_4: "Thank you for your attention",
    foot_5: "Yes, I hope ZhongYan can contact me for news releases, promotions, and events.",
    foot_6: "submit",
    foot_7: "WeChat Account",
    foot_8: "Phone QR",
    foot_9: "Email address cannot be empty",
    foot_10: "The email address does not meet the specifications",
    foot_11: "Please check the user agreement",

    // 手机底部导航栏
    mobile_foot1: "Home",
    mobile_foot2: "Product",
    mobile_foot3: "News",
    mobile_foot4: "Doc",

    // 其他页面
    text1: "Please enter content",
    text2: "loading...",
    text3: "Consult",
    text4: "Corporate",
    text5: "Product",
    text6: "Contact",
    text7: "Notes",
    text8: "We support contact methods such as mobile phone, email, and landline, and we will contact you as soon as possible.",
    text9: "Please select language",
    text10: "Product Details",
    text11: "Documentation",

}
export default EN
