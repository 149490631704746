function getParam(name) {
    var url = window.location.href;
    var urlOne = url.split('#')[1];
    var urlParam = urlOne.split('?')[1];
    if (urlParam) {
        var paramArr = urlParam.split('&');
        var param = {};
        for (var i = 0; i < paramArr.length; i++) {
            param[paramArr[i].split('=')[0]] = paramArr[i].split('=')[1]
        }
        if (param[name]) {
            return param[name];
        }
    }
    return '';
}

function getPageParamStr(page, pageSize) {
    return "&page=" + page + "&rows=" + pageSize
}


export {
    getParam, getPageParamStr
}
