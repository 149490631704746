<template>
    <div class="main home" style="min-height: 800px">
        <!-- 电脑端页面  -->
        <el-row :gutter="20" v-if="!this.GLOBAL.isMobile">
            <el-col :span="19" :offset="4">
                <div style="min-height: 800px">
                    <el-row :gutter="16">
                        <el-col :span="12" :offset="4">
                            <div style="margin: 35px">
                                <el-input :placeholder="$t('text1')" v-model="searchValue" class="input-with-select">
                                    <el-button
                                        slot="append"
                                        icon="el-icon-search"
                                        @click="initPageList()"
                                    ></el-button>
                                </el-input>
                            </div>
                        </el-col>
                    </el-row>

                    <el-row type="flex" class="row-bg">
                        <el-col :span="20">
                            <div style="min-height: 700px">
                                <div style="min-height: 50px; margin: 5px; padding: 10px; border-bottom:1px solid #e4e4e4;" v-for="file in filePageList">
                                    <div style="display: flex; align-items: center">
                                        <div style="max-width: 65rem">
                                            <a target="_blank" :href="[cp + '/jyy/billFile/getFile?uuid=' + file.uuid]">
                                                <span style="font-size: 1rem;color: #0a0a0a;word-break:break-all;">{{ file.billFileName }}</span>
                                            </a>
                                        </div>
                                        <div style="flex: 1;"></div>
                                        <div style="color: #404040;min-width: 140px;margin-left: 10px"> {{ file.createTime }}</div>
                                    </div>
                                </div>
                            </div>
                            <div style="height: 50px; text-align: center">
                                <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :page-size="pageSize"
                                    :pager-count="11"
                                    layout="prev, pager, next"
                                    :total="total"
                                ></el-pagination>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>


        <!-- 手机版页面  -->
        <div id="productListDiv" v-if="this.GLOBAL.isMobile" v-on:touchmove="isShow">
            <div style="min-height: 800px;background-color: #ffffff;">
                <div style=" padding: 80px 10px 10px;">
                    <el-input :placeholder="$t('text1')" v-model="searchValue" class="input-with-select">
                        <el-button slot="append"
                                   icon="el-icon-search"
                                   @click="initPageList()"
                        ></el-button>
                    </el-input>
                </div>
                <div>

                    <div style="min-height: 50px; margin: 5px; padding: 10px; border-bottom:1px solid #e4e4e4;" v-for="file in filePageList">
                        <div style="">
                            <a target="_blank" :href="[cp + '/jyy/billFile/getFile?uuid=' + file.uuid]">
                                <span style="font-size: 1rem;color: #0a0a0a;">{{ file.billFileName }}</span>
                                <span style="color: rgba(50,50,50,0.9);min-width: 140px;margin-left: 10px"> {{ shortTimeDot(file.createTime) }}</span>
                            </a>
                        </div>
                    </div>

                </div>
                <div id="getMoreDiv" style="padding-bottom: 100px">
                    <div v-if="total > page * pageSize" style="text-align: center">
                        {{ $t('text2') }}
                    </div>
                </div>

            </div>


        </div>
    </div>
</template>

<script>
import {getPageParamStr, getParam} from '@/utils/utils'
import axios from '@/utils/axios'
import {shortTimeDot} from "../../utils/dateFormat";

export default {
    data() {
        return {
            cp: this.GLOBAL.cp,
            page: 1,
            pageSize: 10,
            total: 0,
            searchValue: '',
            fileDownloadTypeList: [],
            filePageList: [],
        }
    },
    mounted() {
        this.initPageList()
    },
    methods: {
        shortTimeDot,
        initPageList() {
            let searchValue = encodeURIComponent(this.searchValue)
            let url = '/jyy/portal/selectFileDownloadPage?searchValue=' + searchValue + getPageParamStr(this.page, this.pageSize)
            axios.get(url).then(res => {
                this.page = res.data.page
                this.pageSize = res.data.pageSize
                this.total = res.data.total
                if (this.GLOBAL.isMobile) {
                    this.filePageList = this.filePageList.concat(res.data.rows)
                } else {
                    this.filePageList = res.data.rows
                }
            })
        },
        onClickFileType(id) {
            this.$router.push('/fileDownloadList?type=' + id)
            this.fileType = id
            this.initPageList()
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.initPageList()
        },
        handleCurrentChange(val) {
            this.page = val
            this.initPageList()
        },
        isShow() {
            const element = document.getElementById("getMoreDiv");
            const innerHeight = window.innerHeight;
            const clientHeight = element.clientHeight;
            // console.log(innerHeight)
            // console.log(clientHeight)
            const {top, right, bottom, left} = element.getBoundingClientRect();
            // console.log(innerHeight, clientHeight, top, right, bottom, left)
            if (top < innerHeight - clientHeight + 100 && !this.loading && this.total > this.page * this.pageSize) {
                this.page = this.page + 1
                this.initPageList()
            }
        },
    },
}
</script>

