<template>
    <div style="display: flex;">
        <div class="leftImg" style="width: 50%;">
            <img src="../assets/item_left.png" width="600px" style="margin-right: 50px">
<!--            <video src="../assets/logo.mp4" autoplay loop muted width="600px" style="margin-right: 50px"> </video>-->
        </div>
        <div class="main" style="width: 50%">
            <div class="title">
                <h5>{{ $t('index_9') }}</h5>
            </div>
            <el-form style="width: 600px; margin-top: 50px;margin-left: 50px" :label-position="labelPosition" label-width="80px" :model="formLabelAlign">
                <el-form-item :label="$t('text4')">
                    <el-input v-model="formLabelAlign.custName"></el-input>
                </el-form-item>
                <el-form-item :label="$t('text5')">
                    <el-input v-model="formLabelAlign.productName"></el-input>
                </el-form-item>
                <el-form-item :label="$t('text6') ">
                    <el-input v-model="formLabelAlign.phone"
                              :placeholder="$t('text8')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('text7')">
                    <el-input type="textarea" rows="4" v-model="formLabelAlign.content"></el-input>
                </el-form-item>
                <div style="width: 100%; display: flex; justify-content: center">
                    <el-button @click="submit" type="primary" style="margin: 20px 0 40px 0">{{ $t('foot_6') }}</el-button>
                </div>
            </el-form>
        </div>

    </div>
</template>

<script>
import axios from '@/utils/axios'

export default {
    data() {
        return {
            cp: this.GLOBAL.cp,
            productList: [],
            labelPosition: 'right',
            formLabelAlign: {
                custName: '',
                productName: '',
                phone: '',
                content: '',
                type: 'XMHZ'
            },
        }
    },
    methods: {
        submit() {
            axios.post('/jyy/portal/addConsultation', this.formLabelAlign)
                .then(res => {
                    if (res.data.code == 0) {
                        this.$message({
                            message: res.data.msg,
                            showClose: true,
                            center: true,
                            duration: 5000,
                            type: 'success',
                        })
                        this.$router.back()
                    } else {
                        this.$message({
                            message: res.data.msg,
                            showClose: true,
                            center: true,
                            duration: 5000,
                            type: 'warning',
                        })
                    }
                })
        },
    },
    mounted() {
        this.formLabelAlign.productName = this.$route.query.name
    },
}
</script>

<style lang="less" scoped>

.leftImg {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    h1 {
        margin-top: 50px;
    }
}

.main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .title {
        width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 50px;

        h5 {
            margin-top: 50px;
        }
    }

}
</style>
