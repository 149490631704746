// cn.js
const RU = {
    type: "RU",
    lang: "Русский язык",

    // 首页-页眉字符
    index_1: "Домой",
    index_2: "О нас",
    index_3: "Введение",
    index_4: "Честь",
    index_5: "Культура",
    index_6: "Продукты",
    index_7: "Информация",
    index_8: "Услуги",
    index_9: "Сотрудничество",
    index_10: "После продажи",
    index_11: "Скачать",
    index_12: "Телефон",
    index_13: "",

    // 首页-主体字符
    index_21: "Последние новости",
    index_22: "Новейшие продукты",
    index_23: "Свяжитесь с нами",
    index_24: "Телефон：+86 153-6288-8085",
    index_24_1: "Телефон：+86 153-6288-8085",
    index_25: "Почтовый ящик：sandychanogdzhongyan.co",
    index_25_1: "Почтовый ящик：sandychanogdzhongyan.co",
    index_26: "Адрес：Шахэ, Чанпинский район, Пекин",
    index_27: "",
    index_27_1: "Адрес：",
    index_28: "Baisha Road Пекинский центр развития электронной науки и техники Xinfeida F4 - 4",
    index_28_1: "Baisha Road Пекинский центр развития электронной науки и техники Xinfeida F4 - 4",
    index_29: "Открыть навигацию",
    index_30: "Выберите навигационное программное обеспечение",
    index_31: "AMAP",
    index_32: "Tencent Maps",
    index_33: "Baidu map",
    index_34: "Основанная в 2019 году, Guangdong ZhongYan Research Technology Co., Ltd. является профессиональным разработчиком, производством и продажей радиочастотного считывающего устройства контроллера доступа QR - кодового считывающего устройства для системы распознавания лиц.  С момента своего создания компания всегда придерживалась принципов бизнеса, основанных на талантах, честности и создании бизнеса, собирая элиту фруктов, принимая потребности клиентов в качестве своей ответственности, предлагая зрелые программы с хорошим качеством и низкой ценой.",
    index_35: "Продукты компании в настоящее время широко используются в таких областях, как безопасность контроля доступа, управление каналами и интеллектуальные системы промышленного контроля кампуса.  Zhongying Technology готова с непрерывными шагами, настойчивым и предприимчивым духом, чтобы предоставить большинству клиентов жесткие, идеальные и эффективные продукты и решения, чтобы стать идеальным партнером для вашего развития и беспроигрышных результатов.",
    index_35_1: "Мы тепло приветствуем партнеров из всех слоев общества, чтобы совместно открыть новую главу в развитии науки и техники.  Если у вас есть какие - либо вопросы или намерение сотрудничать с нашими продуктами или услугами, пожалуйста, свяжитесь с нами в любое время.",
    index_36: "Технический потенциал",
    index_37: "Компания последовательно запустила устройство для чтения карт контроля доступа, устройство для чтения карт QR - кода, динамический контроль доступа QR - кода, распознавание лиц, радиочастотный модуль, устройство контроля доступа и другие типы продуктов. ",
    index_38: "Успешные случаи",
    index_39: "Благодаря передовым техническим преимуществам и отличному качеству он получил высокую оценку пользователей!  Кроме того, компания имеет эффективную команду R & D и первоклассные промышленные системы управления, которые могут быть быстро настроены в соответствии с потребностями пользователей, а также общее решение для безопасности доступа и управления каналами.",
    index_40: "Посмотреть последние события",
    index_41: "Посмотреть",
    index_42: "Узнать больше",
    index_43: "После продажи：+86 136-2007-5120",
    index_44: "Техническая поддержка：+86 181-1875-4449",
    index_45: "Guangdong & Shengzhen",
    index_46: "ZHONGYAN TECH",

    // 首页-页脚字符
    foot_1: "Почему выбирают нас",
    foot_2: "Новейшие продукты",
    foot_3: "Доступ к последней информации",
    foot_4: "Спасибо за внимание.",
    foot_5: "Да, надеюсь связаться со мной, чтобы получить новостной бюллетень, продвижение и мероприятия.",
    foot_6: "Представлено",
    foot_7: "Микрофон Public",
    foot_8: "Мобильная страница",
    foot_9: "Адрес почтового ящика не может быть пустым",
    foot_10: "Адрес почтового ящика не соответствует нормам",
    foot_11: "Пожалуйста, выберите пользовательское соглашение.",

    // 手机底部导航栏
    mobile_foot1: "Домой",
    mobile_foot2: "Продукты",
    mobile_foot3: "Информация",
    mobile_foot4: "Скачать",

    // 其他页面
    text1: "Введите содержимое",
    text2: "Загружается...",
    text3: "Консультации",
    text4: "Название компании",
    text5: "Название продукта",
    text6: "Контактная информация",
    text7: "Примечания",
    text8: "Поддерживая телефоны, почтовые ящики, стационарные телефоны и другие контактные данные, мы свяжемся с вами в первый раз",
    text9: "Выберите язык",
    text10: "Подробности продукции",
    text11: "Продуктовый документ",

}
export default RU
